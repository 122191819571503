.button-circle {
    width: 10%;  
    border: solid thin black;            
    border-radius: 3rem;
    font-size: 2.2rem;                
    display: inline-block;
    background-color: black;
    color: white;
    text-align: center;
    animation: fadeOut .5s ease-out .5s 1 backwards;  
    cursor: pointer;

    &:hover {
        background-color: azure;
        color: black;
    }
}