.item-product {       
    width: var(--item-width);
    height: calc(var(--item-height) - var(--item-height) / 5);
    //border: solid thin rgba(black, .05);   
    cursor: pointer;
    margin: 0 auto;

    &__box {
        width: fit-content;
        height: fit-content;
        margin: 0 auto;

        &__image {                        
            height: var(--item-img-height);
        }
    }

    &__name {
        font-size: var(--item-name-font-size);
        letter-spacing: .1rem;
        font-weight: 700;
        display: block;
        text-align: center;
        width: var(--item-name-width, 100%);
        margin: 0 auto;
    }

    &__price {
        font-size: calc(var(--item-name-font-size) + .2rem);
        letter-spacing: .1rem;        
        display: block;
        text-align: center;
    }
}