.textbox-auto__text {
    width: 10rem;    
}

.textbox-auto__container {
    position: absolute;
    height: fit-content;
    border: solid thin rgba(black, .2);
    border-top: none;
    width: 10rem;   
    background-color: white; 
    display: none;
    margin-top: 1rem;        
    
    &__data {
        display: block;
        font-size: 1.2rem;      
        padding-left: .2rem;
        border-bottom: solid thin rgba(black, .1);
        cursor: pointer;
        margin: 0;
        
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            color: turquoise;
        }

        &--selected {
            color: turquoise;
        }
    }
}