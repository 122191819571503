.button-black {        
    font-size: 1.5rem;        
    letter-spacing: .05rem;          
    display: block;  
    cursor: pointer;
    padding: .2rem 4rem;
    text-align: center;   
    border-radius: 30rem;   
    
    &:hover {                         
        background-color: black; 
        color: white;              
    }
}    