.loading-symbol {
    position: absolute;
    right: 6rem;
    bottom: 6rem;    
    width: 5rem;
    height: 5rem;
    display: none;
    
    @include respond(tab_port) {
        top: 3rem;
        right: 16.5rem; 
        width: 4.8rem;
        height: 4.8rem;
    }

    &__box {
        position: relative;
        width: 100%;
        height: 100%;

        &--lonely {
            background-color: cornflowerblue;
            border-radius: 100%;
        }

        &__dot {
            position: absolute;
            left: 2rem;
            top: 2rem;
            height: 1.4rem;
            width: 1.4rem;
            border-radius: 50%;
            background-color: cornflowerblue;            

            &--anim1 {
                animation: loading 1s cubic-bezier(.5, .1, .2, 1) infinite;
            }

            &--anim2 {
                animation: loading 1s cubic-bezier(.5, .1, .3, 1) infinite;
            }

            &--anim3 {
                animation: loading 1s cubic-bezier(.5, .1, .4, 1) infinite;
            }     
        }
    }
}