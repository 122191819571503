.navbar {    

    &__menu-side {
        position: absolute;
        top: 0;
        right: 0;
        display: none;        
        width: 0;
        height: 100vh;
        background-color: black;        

        &__option {
            //position: absolute;
            //bottom: 16rem;
            font-size: 1.4rem;
            letter-spacing: .1rem;
            cursor: pointer;
            width: 100%;  
            text-align: center;
            color: white;
            padding-bottom: .2rem;   
            
            &:first-child {
                margin-top: 22vh;
            }

            &:last-child {
                position: absolute;
                bottom: 16rem;
            }
            
            &:hover {
                font-weight: 900;
            }
        }

        &--active {
            display: block;
        }
    }

    &__menu {
        position: absolute;
        cursor: pointer;
        background-color: black;
        color: white;
        font-size: 2.4rem;
        width: fit-content;
        height: fit-content;
        top: 3rem;
        right: 1rem;
        padding: .3rem 1.2rem;
        border-radius: 100%;
        border: solid thin black;
        animation: falling .5s ease-out .3s 1 backwards;        

        &:hover {
            color: black;
            background-color: azure;
        }
    }
}