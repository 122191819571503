.product-preview-customer {
    width: fit-content;
    height: var(--preview-height);  
    margin: 0 auto;
    margin-top: 4rem;
    padding-top: 3rem;

    &--min {
        width: calc(100vw - 6rem); 
        height: fit-content;
        margin: 0 auto;    
        margin-top: 10rem;    
    }

    &--med {
        width: var(--preview-height);
        height: fit-content;
        margin: 0 auto;    
        margin-top: 10rem;  
    }

    &__table {
        height: var(--preview-table-height);
        float: left;

        &--min {
            width: fit-content; 
            height: fit-content;
            margin: 0 auto;
            float: none;
        }

        &__box {
            width: fit-content;
            height: fit-content;
            cursor: pointer; 
            margin: 0 auto;
            
            &--min {
                display: inline-block;
            }

            &__image {
                height: var(--preview-table-image-height);               
            }
        }
    }

    &__box {
        width: fit-content;
        height: fit-content;
        float: left;
        margin-left: 2rem;
        position: relative; 
        
        &--min {
            margin-left: 0;
            margin-top: .5rem;
        }

        &__image {
            height: var(--preview-height);  
            
            &--min {
                width: calc(100vw - 6rem); 
                height: unset;
            }

            &--med {
                width: var(--preview-height);
                height: unset;
            }
        }

        &__magnifier {
            position: absolute;
            border: .3rem solid $baseColor;
            border-radius: 50%;
            cursor: none;              
        }
    }
}