@mixin respond($breakpoint) { 
    @if $breakpoint == screen_min {
        @media only screen and (max-width: 81.25em) { @content }; //1300px
    }    
    @else if $breakpoint == tab_land {
        @media only screen and (max-width: 75em) { @content }; //1200px
    }    
    @else if $breakpoint == tab_port {
        @media only screen and (max-width: 56.25em) { @content }; //900px
    }
    @else if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content }; //600px
    }

    @if $breakpoint == laptop {
        @media only screen and (max-height: 56.25em) { @content }; //900px
    }
}