.category-side-lib {
    width: 32vw;
    height: 100vh;
    overflow: hidden;
    background-color: $baseColor;
    float: left;  
    
    @include respond(tab_port) {
        width: 100%;       
    }
    
    &__info-header {
        height: 20.5vh;
        width: 100%;
        position: relative;

        @include respond(tab_port) {
            width: 100%;
            //padding-left: 7%;            
            background-color: $baseColor;
        }
        
        &--min {
            height: 12vh;

            @include respond(laptop) {                
                height: 20.5vh;
            }            
        }       

        &__set {
            width: fit-content;  
            height: fit-content;                      
            border-radius: 100%;
            font-size: 2.4rem;   
            padding: .3rem 1.2rem;                         
            position: absolute;
            right: 3%;
            top: 3rem;   
            
            @include respond(tab_port) {
                right: 6rem;                
            }
        }        

        &__holder {
            display: block;
            width: 100%;

            &__back {
                width: fit-content;  
                height: fit-content;                          
                border-radius: 100%;
                font-size: 2.4rem;   
                padding: .3rem 1.2rem;             
                margin-left: 2rem;                                 
            }

            &__path {
                font-size: 2.1rem;
                letter-spacing: .1rem;
                color: black;
                display: inline-block;
                text-align: center;
                padding-left: 3%;
                padding-top: 1rem;
            }
        }
    }

    &__container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 83.5vh;
        width: 100%;  
        
        @include respond(tab_port) {            
            height: 100 - 20.5vh;           
        }
        
        &--min {
            height: 88vh;

            @include respond(tab_port) {               
                height: 100 - 20.5vh;
                margin-top: 7%;                
            }
        }

        &__item {
            margin-bottom: 3rem;
            width: 100%;

            @include respond(tab_port) {                
                width:  100%;
            }
            
            &__del {                
                margin-right: 12%;
                margin-left: 2.65%;  
                                
                
                @include respond(tab_port) {
                    margin-left: 2%;
                    margin-right: 5%;
                }

                &--none {
                    display: none;
                }
            }
            
            &__move {
                height: 5vh;
                width: 3rem;
                display: grid;                   
                float: right;
                margin-right: 3rem;                                     

                &__up, &__down {
                    width: 100%;
                    height: 2vh;
                    font-size: 1rem;
                    display: block;
                }                

                &--none {
                    display: none;
                }
            }       

            &__name {
                display: inline-block;
                outline: none;
                border: none;
                background-color: black !important;
                cursor: unset !important;
                color: white;
                font-size: 2.2rem !important;
                letter-spacing: .1rem !important;                
                padding: 2% 0 !important;
                width: 50% !important;
                border-radius: 3rem !important;
                text-align: center;
                animation: slideIn .5s ease-out .3s 1 backwards; 
                margin-left: 25%;               
                
                @include respond(tab_port) {
                    width: 60 !important;
                    margin-left: 15 !important; 
                }
                
                &--change {
                    background-color: white !important;
                    color: black;
                    margin-left: 0;
                }                
            }

            &__open {                              
                margin-left: 10%;
                padding: .5% 1%;               
                font-weight: 700;     
                
                @include respond(tab_port) {
                    margin-left: 5%;
                }
            }
        }

        &__add {           
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: 100%;

            &--none {
                display: none;
            }
        }
    }
}