.pop-up-form {
    height: 21vh;
    width: 30vw;
    position: absolute;
    left: 35vw;
    top: 35vh;
    background-color: white;
    border: solid thin rgba(black, .5);    

   @include respond(tab_port) {
        height: 30vh;
        width: 75vw;
        left: 12.5vw;
   }
   
    &__static {        
        display: block;        
        font-size: 1.6rem;
        letter-spacing: .15rem;
        text-align: center; 
        margin-top: 3vh;       
    }    

    &__item {
        display: block;
        font-size: 1.8rem;
        letter-spacing: .15rem;
        font-weight: 700;
        text-align: center; 
        margin-top: 2rem;
    }

    &__btn-no {        
        float: left; 
        margin: 1rem;
        margin-left: 20%;    
        
        @include respond(tab_port) {
           width: 12vw;
       }
    }

    &__btn-yes {
        float: right;
        margin: 1rem;
        margin-right: 20%; 

        @include respond(tab_port) {
            width: 12vw;
       }
    }
}