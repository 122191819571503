@keyframes falling {
    0% {
        opacity: 0;
        transform: translateY(-5rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes loading {
    from {
        transform: rotate(0deg) translateX(3rem) rotate(0deg);
    }

    to {
        transform: rotate(360deg) translate(3rem) rotate(-360deg);
    }
}