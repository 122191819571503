.category-virtual-side-lib {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(black, .4);
    width: 100vw;
    height: 100vh;

    &__window {
        width: 50vw;
        height: 100vh;
        background-color: $baseColor;
        margin: 0 auto;

        @include respond(tab_port) {
            width: 75vw;
        }

        &__header {
            height: 14vh;

            &__title {
                font-size: 2.2rem;
                letter-spacing: .1rem;
                display: block;
                text-align: center;
                padding-top: 3rem;
                color: black;
            }

            &__info {
                width: 100%;

                &__back {
                    margin-left: 2rem;

                    @include respond(tab_port) {
                        width: 3.9rem;
                    }
                }

                &__path {
                    font-size: 2rem;
                    letter-spacing: .1rem;                    
                    padding-left: 1rem;
                }

                &__set {
                    float: right;
                    margin-right: 2rem;

                    @include respond(tab_port) {
                        width: 3.9rem;
                    }
                }
            }
            
        }

        &__container {
            height: 86vh;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;            

            &__item {
                width: 34rem;
                height: 5vh;
                margin: 0 auto;
                padding: 1.4rem 0;  
                
                @include respond(tab_port) {
                    width: 100%;
                }

                &:first-child {
                    margin-top: 3rem;                   
                }

                &__name {
                    font-size: 2rem !important;
                    letter-spacing: .1rem !important;                    
                    padding-left: 1rem !important;
                    outline: none;
                    border: none;
                    background-color: black !important;
                    color: white !important;
                    border-radius: 30rem !important;
                    padding: 1rem .6rem !important;
                    text-align: center !important;
                    width: 24rem !important;
                    display: inline-block !important;
                    cursor: unset !important;

                    @include respond(tab_port) {
                        width: 60% !important;
                        margin-left: 10% !important;
                    }
                }

                &__move {
                    height: 5vh;
                    width: 3rem;
                    display: grid;                   
                    float: right; 
                    
                    @include respond(tab_port) {
                        margin-right: 2rem;
                    }
    
                    &__up, &__down {
                        width: 100%;
                        height: 2vh;
                        font-size: 1rem;
                        display: block;                        
                    }              
                  
                    &--none {
                        display: none;
                    }
                }

                &__open {
                    padding: .1rem .5rem;
                    margin-left: 2rem;
                }
            }
        }
    }
}