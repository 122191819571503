.product-lib {
    width: 68vw;
    height: 100vh;
    overflow: hidden;
    float: right;

    @include respond(tab_port) {
        width: 100vw;       
        float: none;        
    }

    &__header {
        border-bottom: solid thin rgba(black, .1);
        height: 10vh;

        @include respond(laptop) {
            height: 13vh;
        }

        @include respond(tab_port) {
            height: 18.5vh;       
        }

        &__title {
            width: 50%;
            height: 6vh;
            font-size: 2.5rem;
            letter-spacing: .1rem;
            color: black;
            display: block;
            text-align: left;            
            float: right;  
            margin-top: 2vh;  
            
            @include respond(tab_port) {
                width: 100%;
                text-align: center;
                padding-top: 3%;  
            }
        }

        &__category {
            width: 45%;
            height: 6vh;                
            float: left;
            margin-top: 2vh; 
            margin-left: 5%;

            @include respond(tab_port) {
                width: 100%;
                margin-left: 2%;
            }
            
            &__main, &__sub {
                font-size: 1.7rem;
                letter-spacing: .1rem;
                color: black;
                display: block;
                text-align: center;
                width: fit-content;

                @include respond(tab_port) {
                    font-size: 1.3rem;
                }
            }
        }
    }

    &__add {
        position: absolute;
        top: 3rem;
        right: 6rem;
        width: fit-content;
        height: fit-content;
        padding: 0.47rem 1.7rem;

        @include respond(tab_port) {
            display: none;
        }
    }

    &__other {
        position: absolute;
        top: 3rem;
        right: 11rem;
        width: fit-content;
        height: fit-content;
        padding: 0.47rem 1.7rem;

        @include respond(tab_port) {
            display: none;
            right: 16.2rem;
        }
    }

    &__switch {
        width: fit-content;  
        height: fit-content;                      
        border-radius: 100%;
        font-size: 2.4rem;   
        padding: .3rem 1.2rem;                         
        position: absolute;
        right: 11rem;
        top: 3rem;   
        display: none;
        
        @include respond(tab_port) {
            display: block;            
        }
    }

    &__container {
        height: 90vh;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        @include respond(laptop) {
            height: 87vh;
        }

        @include respond(tab_port) {            
            height: 100 - 18.5vh;
        }
    }
}

.product-item {
    width: 100%;
    height: 17vh;
    border-bottom: solid thin rgba(black, .1);

    @include respond(laptop) {
        height: 23vh;
    }

    &__col-menu {
        width: 8%;
        height: 100%;
        float: left;
        margin-left: 2%;       

        @include respond(tab_port) {
            width: 13%;
        }

        &__mod, &__del, &__off, &__reco {
            width: 80%;
            height: fit-content;           
            display: block;
            margin: 10% 0;
            font-size: 1.3rem;
            cursor: pointer;

            @include respond(tab_port) {
                font-size: 1.1rem;
            }

            &:hover {
                text-decoration: underline;
            }
        }
        
        &__off {            
            margin-top: 30%;
        }
    }

    &__col-left {
        width: 20%;
        height: 100%;
        float: left;

        @include respond(tab_port) {
            width: 22%;
        }

        &__box {
            width: fit-content;
            height: 10vh;
            padding-top: 3.5vh;
            margin: 0 auto; 
            
            @include respond(tab_port) {
                padding-top: 1vh;
                height: 8vh; 
            }

            &__image {
                height: 12vh;  

                @include respond(laptop) {
                    height: 18vh;
                }
                
                @include respond(screen_min) {
                    height: 11vh; 
                }

                @include respond(tab_port) {
                    height: 8vh; 
                }
            }
        }
    }

    &__col-right {
        width: 70%;
        height: 100%;
        float: right;

        @include respond(tab_port) {
            width: 63%;
        }

        &__row-0 {
            width: 100%;
            height: 4vh;
            padding: 1vh;

            @include respond(laptop) {
                height: 7vh;
            }

            &__name {
                font-size: 1.9rem;
                letter-spacing: .1rem;
                color: black;
                display: block;
                text-align: left;
                width: 60%;
                float: left;   
                
                @include respond(tab_port) {
                    font-size: 1.3rem;
                }
            }

            &__price {
                font-size: 1.9rem;
                letter-spacing: .1rem;
                color: black;
                display: block;
                text-align: left;
                width: 40%;
                float: right;

                @include respond(tab_port) {
                    font-size: 1.3rem;
                }
            }
        }

        &__row-1 {
            width: 100%;
            height: 12vh; 
            
            @include respond(laptop) {
                height: 15vh;
            }
            
            &__category-virtual-container {
                width: 30%;
                height: 9vh;                
                float: left;  
                
                @include respond(tab_port) {
                    width: 35%;
                }

                &__item {
                    font-size: 1.3rem;
                    letter-spacing: .12rem;
                    color: black;
                    display: block;
                    text-align: left;
                    width: 100%;
                    height: 3vh;
                    padding-left: 1rem;

                    @include respond(tab_port) {
                        font-size: 1rem;
                    }

                    &--dots {
                        font-size: 1.6rem;
                        text-align: left;
                        line-height: 1rem;
                        padding-left: 1rem;    
                        
                        @include respond(tab_port) {
                            font-size: 1.3rem;
                        }
                    }
                }
            }  
            
            &__desc {
                font-size: 1.2rem;
                letter-spacing: .1rem;
                color: black;
                display: block;
                text-align: center;
                width: 55%;
                //margin-right: 5%;
                height: 9vh;
                float: left;

                @include respond(tab_port) {
                    font-size: 0.9rem;
                    width: 50%;
                }
            }

            &__move {
                height: 5vh;
                width: 7%;
                display: grid;                   
                float: right;
                margin-right: 3%;                                     

                &__up, &__down {
                    width: 100%;
                    height: 2vh;
                    font-size: 1rem;
                    display: block;
                }                                
            }
        }
    }

    &--sketch {
        background-color: rgba(black, .03);
    }
}