.product-preview {    
    height: 44vh;
    width: fit-content;
    margin: 2rem auto; 
    
    @include respond(tab_port) {
        height: 33vh;        
    }

    &__table {                    
        float: left;
        margin-right: 1vw;

        &__box {
            height: 10.65vh;
            width: fit-content;
            margin: 0 auto;

            @include respond(tab_port) {
                height: 7.5vh;        
            }

            &__image {
                height: 10.65vh;

                @include respond(tab_port) {
                    height: 7.5vh;        
                }
            }
            
            &:not(:last-child) {
                margin-bottom: .5vh;
            }

            &--active {
                border: solid red;
            }
        }

        &__box-dub {
            height: 10.65vh;
            width: 2vw;
            margin: 0 auto;
            display: none;

            &--active {
                display: block;
            }
        }
    }

    &__box {       
        float: left;        
        
        &__image-view {
            height: 44vh;

            @include respond(tab_port) {
                height: 33vh;        
            }
        }
    }

    &__layer {
        width: 100%;
        height: 100%;           
        background-color: rgb(242, 242, 255);
    }

    &--empty {
        width: 70%;
    }
}