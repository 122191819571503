.product {
    width: $dirProductWidth;   
    height: $contentHeight; 
    float: left;    
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;       

    &--min {
        width: 100vw;
        height: calc(100vh - 7rem);
        margin-top: 7rem;          
    }

    &--med {
        width: $dirProductWidth;
        height: $contentHeight;    
    }

    &__sort, &__dir, &__inf {
        position: absolute;
        top: 2rem;
        left: 3rem;
        width: 13rem;
        font-size: 1.2rem; 
        padding: .005rem 2rem;
        border: solid $baseColor; 
        color: gray;

        &--min {
            top: 2rem;
            left: 3rem;
        }
    }

    &__dir {
        left: 27rem;

        &--min {
            top: 5rem;
            left: 3rem;
        }
    }

    &__inf {
        left: 51rem;

        &--min {
            top: 8rem;
            left: 3rem;
        }
    }
    
    &__name, &__price {
        display: block;        
        font-size: var(--product-name-size);
        letter-spacing: .1rem;
        margin-top: 2rem;
        padding: 0 3rem;
        text-align: center;     
    }

    &__price {
        font-size: var(--product-price-size);
        //font-weight: 700;
        margin-top: 1rem;
    }

    &__categories {
        width: $dirProductWidth - 6rem;
        height: fit-content;        
        padding: 3rem 3rem;    
        
        &--min {
            width: calc(100vw - 6rem);
        }       

        &__col-1, &__col-2, &__col-3 {
            float: left;
            width: 33%;
            margin-bottom: 3rem;

            &--min {
                width: 100%;
            }
        }        
    }

    &__description {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        line-height: 1.7;
        color: black;
        background-color: white !important;
        cursor: unset !important;
        box-shadow: unset !important;

        display: block;
        font-size: var(--product-description-size);
        letter-spacing: .1rem;
        padding: 0 3rem; 
        padding-bottom: 3rem;     
        word-wrap: break-word;
        resize: none;
        border: none !important; 
        outline: none; 
        width: calc(100% - 6rem);         
    }
}