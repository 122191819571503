.image-side-lib {
    height: 83.5vh;
    width: 100%;

    &__bin {
        font-size: 16rem;
        height: 30%;    
        width: 100%;    
        text-align: center;
        border: solid thin rgba(black, .3);
       
        &--active {
            color: red;
            border-color: red;
        }

        @include respond(laptop) {
            height: 40%;
        }

        @include respond(tab_port) {
            display: none;
        }     
                
    }
}

.drag-n-drop {
    height: 70%;
    width: 100%;

    @include respond(laptop) {
        height: 60%;
    }
    
    @include respond(tab_port) {
        height: 100%;
    }    

    &__container {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;       
        
        display: grid;
        grid-auto-rows: 15vh;
        grid-template-columns: 8vw 8vw 8vw 8vw;
        margin: 0 auto;       
        
        @include respond(tab_port) {
            width: 100vw;
            grid-auto-rows: 18.5vh;
            grid-template-columns: 25vw 25vw 25vw 25vw;                        
        }

        @include respond(phone) {
            width: 100vw;                       
            grid-auto-rows: 18.5vh;
            grid-template-columns: 30vw 30vw 30vw;   
            margin-left: 5vw;                    
        }

        &__box {
            height: fit-content;
            width: 8vw;
            margin: 0 auto;

            @include respond(tab_port) {
                width: 25vw;
            }

            @include respond(phone) {
                width: 30vw;
            }
            
            &__img {
                height: 8vh;
                display: block;
                margin-left: auto;
                margin-right: auto;  

                @include respond(tab_port) {
                    height: 12vh;
                }
            }

            &__name {
                display: block;
                font-size: 1.1rem;
                text-align: center;
                word-break: break-word;
            }

            &--selected {
                background-color: rgba(blue, .3);
            }
        }
    }    
}