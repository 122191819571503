.pnl-info {
    height: 100vh;
    width: 100vw;
    background-color: $baseColor;
    position: absolute;
    top: 0;
    left: 0;

    &__back {
        width: fit-content;  
        height: fit-content;                          
        border-radius: 100%;
        font-size: 2.4rem;   
        padding: .3rem 1.2rem;             
        position: absolute;
        left: 2.5%;
        top: 1rem;
    }

    &__save-box {
        position: absolute;
        width: 17rem;
        left: 10%;
        top: 2rem;     

        &__save-product {
            width: 12.4rem;
            height: 2.8rem;
            font-size: 1.62rem;            
            margin-right: 5%;
            margin-bottom: 6vh;                
        }
    
        &__save-indi {
            width: 3rem;
            height: 3rem;
            background-color: lime;        
            border-radius: 100%;   
            position: absolute;
            right: 0; 
            top: 0;  
            display: none;           
        }
    }

    &__col {
        width: 50%;
        height: 100%;
        float: left;

        &__write {
            width: 90%;
            height: 80%;
            outline: none;
            word-wrap: break-word;
            resize: none;
            border: none;
            margin-top: 15%;
            margin-left: 5%;
        }

        &__read {
            width: 90%;
            height: 80%;
            margin-top: 15%;
            margin-left: 5%;
            background-color: white;
        }
    }    
}