.dir-sort {
    width: $dirSortWidth;
    height: $contentHeight;   
    background-color: $baseColor; 
    
    &__close {        
        position: absolute;
        top: 1rem;
        right: 2rem; 
        width: 4rem;
        display: none;
        
        &--active {
            display: block;
        }
    }

    &__category-container {
        width: 100%;
        height: 100%;                
        display: grid;
        grid-template-columns: 100%;  
        grid-auto-rows: max-content;     
        overflow-y: auto;
        overflow-x: hidden;                   
    }  
    
    &--left {
        float: left;
    }

    &--right {
        float: right;
    }

    &--min {
        position: absolute;
        top: 0;
        height: 100vh;
        width: 100%;        
        display: none;          
    }

    &--active {
        display: block;
        z-index: 10;
    }
}