.look-on {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(black, .3);

    &__box {
        width: fit-content;
        height: fit-content;
        margin: auto;
        margin-top: 10vh;

        &__image {
            height: 80vh;
        }
    }
}