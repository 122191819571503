.loading-sign {
    width: $dirProductWidth;
    height: 100vh;
    position: absolute;
    top: 0;
    left: $dirSortWidth;
    background-color: rgba(black, .5);
    display: none;

    &--min {
        left: 0;
    }

    &__box {
        width: 20rem;
        height: 14rem;
        border: solid $baseColor;
        border-radius: 30%;
        margin: 30% auto;

        &__sign {
            font-size: 1.2rem;
            letter-spacing: .1rem;
            color: white;
            width: 16rem;            
            margin: 25% auto;
        }
    }

    &--active {
        display: block;
    }
}