.navbar-line {
    width: 100vw;
    height: $navbarHeight;
    background-color: $navbarColor;
    position: relative;

    &__item {
        position: absolute;
        width: fit-content;
        margin-left: 1%;
        margin-top: .35rem;
        border-right: solid thin rgba(black, .05);
        border-left: solid thin rgba(black, .05);
        padding: 0 2rem;

        &__title {
            font-size: 1.2rem;
            margin-left: .5rem;
        }       
    }
}