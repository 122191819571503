.item-category-sub {          
   width: 100%;

    &__name {
        font-size: 1.2rem;
        padding: .05rem 3rem;        

        &--left {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &--right {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        &--min {
            padding: .7rem 3rem; 
            font-size: 1.5rem;
        }
    }
}