.dir-product {
    width: $dirProductWidth;
    float: left;
    height: $contentHeight;
    overflow-y: auto;
    overflow-x: hidden;    
    position: relative;    

    &--min {
        width: 100vw;
        height: calc(100vh - 7rem);
        margin-top: 7rem;   
    }

    &__header {
        height: 15vh;       

        &__title-main {
            display: block;
            text-align: center;
            font-size: 1.7rem;
            letter-spacing: .1rem;
            padding-top: 3rem;            
        }

        &__title-container {

            &__title {
                display: inline-block;
                text-align: left;
                font-size: 1.2rem;
                letter-spacing: .1rem;
                padding-top: 7rem;
                padding-left: 3rem;
            }
        }

        &__sort {
            position: absolute;
            top: 2rem;
            left: 3rem;
            width: 13rem;
            font-size: 1.2rem; 
            padding: .005rem 2rem;
            border: solid $baseColor;
            color: gray;
        }
    }    

    &__container {
        display: grid;
        width: 100%; 
        height: fit-content;
        margin: 0 auto; 
        grid-auto-rows: var(--item-height);             
        grid-template-columns: var(--item-grid-template);   
        /*overflow-y: auto;
        overflow-x: hidden;*/
    }

    &__sep {
        width: 90%;
        height: .3rem;
        background-color: rgba(black, .2);
        margin: 3rem auto;
    }
}