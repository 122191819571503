.product-mod-board {
    width: 68vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    float: right;

    @include respond(tab_port) {
        width: 100vw;       
        float: none;        
    }

    &__toProducts {
        position: absolute;
        right: 1rem;
        top: 8rem;  
        width: fit-content;
        height: fit-content; 
        padding: .45rem 1.65rem;                
    }

    &__switch {
        width: fit-content;  
        height: fit-content;                      
        border-radius: 100%;
        font-size: 2.4rem;   
        padding: .3rem 1.2rem;                         
        position: absolute;
        right: 1rem;
        top: 13rem;   
        display: none;
        
        @include respond(tab_port) {
            display: block;            
        }
    }

    &__holder-top {
        width: 100%;
        height: 15vh;

        &__name {
            width: 60%;
            display: block;        
            margin-top: 5rem;
            margin-bottom: 2rem;
            margin-left: 5%;
            font-size: 1.7rem;
            float: left;

            @include respond(tab_port) {
                width: 55%;                       
            }
        }

        &__price {
            width: 20%;
            display: block;        
            margin-top: 5rem;
            margin-bottom: 2rem;    
            margin-right: 1%;        
            font-size: 1.7rem;
            float: right;

            @include respond(tab_port) {
                width: 17%;  
                margin-right: 2%;                      
            }
        } 
    
        &__currency {
            width: 4%;
            display: block;
            float: right;
            margin-top: 5rem;
            margin-bottom: 2rem;
            margin-right: 5%;
            font-size: 1.7rem;
        }                        
    }

    &__category-container {
        width: 100%;
        height: 20vh;
        overflow-x: hidden;
        overflow-y: auto;        
        background-color: rgb(252, 252, 255);     
    }

    &__category-list {
        width: 60%;
        height: 5vh;  
        margin: 0 auto;  

        @include respond(laptop) {
            height: 4.5rem;     
        }
        
        @include respond(tab_port) {
            width: 80%; 
            height: 4.5rem;                        
        }
        
        &__del {
            width: 3.8rem;            
            float: left;
        }

        &__add {
            width: 3.8rem; 
            height: 3.8rem;  
            margin-left: 51.8%;  
            
            @include respond(tab_port) {
                width: 10%; 
                margin-left: 45%;                       
            }
        }

        &__holder {
            width: 85%;
            float: right;
            padding-top: 1%;

            &__name {
                width: 42.5% !important;                

                @include respond(tab_port) {
                    width: 42.5%;                        
                }
            }

            &__colon {
                width: 2.5%;
                font-size: 1.8rem;
                margin-left: .5%;
                margin-right: .5%;
                display: inline-block !important;
                cursor: unset !important;
            }

            &__value {
                width: 42.5% !important;                                

                @include respond(tab_port) {
                    width: 42.5% !important;                        
                }
            }
        }
    }

    &__holder-bot {
        width: 100%;
        height: 22vh;
        margin-top: 5vh;
        margin-bottom: 4vh;

        &__description {
            width: 90%;
            height: 21vh;
            margin-left: 5%;
            word-wrap: break-word;
            resize: none;
            font-size: 1.4rem;  
            background-color: white !important;
            cursor: unset !important;
            box-shadow: unset !important;                    
            
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            line-height: 1.7;
            color: black;   
        }
    }

    &__save-box {
        position: relative;
        width: 17rem;
        margin: 0 auto;

        @include respond(tab_port) {           
            margin-bottom: 30vh;            
        }

        &__save-product {
            width: 12.4rem;
            height: 2.8rem;
            font-size: 1.62rem;            
            margin-right: 5%;
            margin-bottom: 6vh;                
        }
    
        &__save-indi {
            width: 3rem;
            height: 3rem;
            background-color: lime;        
            border-radius: 100%;   
            position: absolute;
            right: 0; 
            top: 0;  
            display: none;           
        }
    }
}