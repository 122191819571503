.pnl-sort {
    width: 100vw;
    height: 7rem;
    margin-top: 3rem;
    position: absolute;
    top: 0;
    left: 0; 
    display: none;
    background-color: white;
    z-index: 9;    
    
    &__btn {
        border-radius: 0;
        border: solid $baseColor;
        color: gray;

        &:last-child {
            border-top: none;
        }
    }
    
    &--active {
        display: block;
    }    
}