.item-category-main {    
    width: 93%;

    &--min {
        width: 30rem;  
        margin: 0 auto;       
    }
    
    &--left {
        padding-left: 7%;
    }

    &--right {
        padding-right: 7%;
    }

    &__box {       

        &__name {    
            font-size: 1.6rem;
            font-weight: bold;
            color: black;  
            display: block;              
            padding: 0;

            &--min {
                font-size: 1.8rem;
            }

            &--left {
                text-align: left;
            }

            &--right {
                text-align: right;
            }
        }
    }

    &__sub-container {
        display: grid;
        grid-template-columns: 100%;              

        &--left {
            border-left: solid thin black;
            justify-items: start;
        }      
        
        &--right {
            border-right: solid thin black;
            justify-items: end;
        }
    }
}