.item-category-virtual {
    height: 3rem;

    &__data {
        font-size: var(--product-virtual-size);
        letter-spacing: .1rem;
        display: block;
        width: 100%;
        margin: 0 auto;     
        
        &__value {
            cursor: pointer;
            text-decoration: underline;            
        }
    }
}