.login {    
    position: absolute;      
    background-color: white;
    border-radius: 100%;
    overflow: hidden;
    width: 35vw;
    height: 35vw;    
    margin: 10rem 32.5vw;

    @include respond(phone) {
        width: 95vw;
        height: 95vw;
        margin: 10rem 2.5vw;
    }

    @include respond(tab_land) {
        width: 55vw;
        height: 55vw;
        margin: 10rem 22.5vw;
    }

    @include respond(tab_port) {
        width: 75vw;
        height: 75vw;
        margin: 10rem 15vw;
    }    

    &__username, &__password {
        display: block !important;
        font-weight: 700;
        font-size: 1.36rem; 
        width: 50% !important; 
        margin: 0 auto !important;
        margin-bottom: 2rem !important; 
        padding: .4rem 1rem !important;

        @include respond(phone) {
            width: 65% !important;
        }
    }

    &__username {
        margin-top: 30% !important;

        @include respond(tab_port) {
            margin-top: 30% !important;
        }
    }

    &__btn {
        font-size: 1.5rem;
        color: white !important;
        background-color: black !important;
        border: solid thin black !important;  
        display: block;
        cursor: pointer !important;        
        margin: 0 auto;
        margin-top: 5rem;        
        border-radius: 3rem;
        width: 14rem;
        padding: .4rem 1rem;  
        text-align: center;          

        &:hover {
            color: black !important;
            background-color: azure !important;                                  
        }
    }

    &__msg {
        font-size: 1.1rem;
        color: crimson;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 2rem;
    }
}