body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: black;    
}

.typ-font-huge {
    font-size: 2.5rem;
    letter-spacing: .1rem;
    text-align: center;
    color: black;
    padding-top: 3vh;
    display: block; 
    
    @include respond(tab_port) {
        text-align: unset;
        padding-left: 2vh;
    }
}