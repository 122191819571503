.information {
    width: $dirProductWidth;   
    height: $contentHeight; 
    float: left;    
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;       

    &--min {
        width: 100vw;
        height: calc(100vh - 7rem);
        margin-top: 7rem;       
    }

    &__container {
        margin-top: 10rem;
    }

    &__sort, &__dir {
        position: absolute;
        top: 2rem;
        left: 3rem;
        width: 13rem;
        font-size: 1.2rem; 
        padding: .005rem 2rem;
        border: solid $baseColor; 
        color: gray;

        &--min {
            top: 2rem;
            left: 3rem;
        }
    }

    &__dir {
        left: 27rem;

        &--min {
            top: 5rem;
            left: 3rem;
        }
    }   
}