:root {
    --window-width: 0;

    --sort-mod: 0;
    --product-mod: 0;

    --item-width: 0;
    --item-height: 0;
    --item-img-height: 0;
    --item-name-font-size: 0;
    --item-grid-template: 0;

    --preview-height: 0;
    --preview-table-height: 0;
    --preview-table-image-height: 0;

    --product-name-size: 0;
    --product-price-size: 0;
    --product-virtual-size: 0;
    --product-description-size: 0;
}

//SIZE
$dirSortWidth: calc(var(--window-width) * var(--sort-mod));
$dirProductWidth: calc(var(--window-width) * var(--product-mod));

$navbarHeight: 3rem;
$contentHeight: calc(100vh - 3rem);

//COLORS
$baseColor: rgb(210, 210, 210);
$navbarColor: rgb(240, 240, 240);